import React from 'react';
import s from './border.module.scss';
import { c } from '../../utils';
import {ComponentProps} from '../../types';

export type BorderProps = {
  size?: 'small'|'large'
} & ComponentProps;
const Border = ({ size='large', className, children, ...props }: BorderProps) => {
  return (
    <div className={c(s.border, s[size], className)}
         {...props}>
      {children}
    </div>
  );
};

export default Border;