import {ComponentProps} from '../../types';
import {c} from '../../utils';
import s from './modal.module.scss';
import React from 'react';

const Close = ({...props}: ComponentProps) => (
  <svg viewBox="0 0 24 24"
    className={s.close}
    {...props}>
    <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
  </svg>
);

export type ModalProps = {
  show?: boolean;
  onClose: () => any;
} & ComponentProps;
const Modal = ({ show, onClose, className, children, ...props }: ModalProps) => {
  return <>
    <div className={c(s.background, show && s.show)}
      onClick={onClose} />
    {show && <div className={c(s.modal, show && s.show, className)}
      {...props}>
      <Close onClick={onClose}/>
      {children}
    </div>}
  </>;
};

export default Modal;