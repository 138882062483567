import React from 'react';
import s from './letters.module.scss';
import { c } from '../../utils';
import {ComponentProps} from '../../types';

export type LettersProps = {
  height?: number
} & ComponentProps

const Letters = ({ height,  className, ...props }: LettersProps) => {
  return (
    <div className={c(s.letters, className)}
         {...props}>
      <div>B</div>
      <div className={s.pipe} />
      <div>V</div>
    </div>
  );
};

export default Letters;