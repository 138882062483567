/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react';
import '../index.scss';
import {graphql, Link} from 'gatsby';
import MadeWithLove from '../components/MadeWithLove/MadeWithLove';
import Letters from '../components/Letters';
import {Parallax} from 'react-scroll-parallax';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {ComponentProps} from '../types';
import ScrollArrow from '../components/ScrollArrow';
import ImageGrid from '../components/ImageGrid';
import Border from '../components/Border';
import { Helmet } from 'react-helmet';
import {addRipple} from '../utils';
import Modal from '../components/Modal';

export const query = graphql`
  query {
    stepsImage: file(relativePath: { eq: "shoot_steps.jpg" }) {
      childImageSharp {         
        gatsbyImageData(width: 600, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    bppImage: file(relativePath: { eq: "shoot_bpp.jpg" }) {
      childImageSharp {         
        gatsbyImageData(width: 600, height: 800, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }`;

type SectionProps = {
  onEnter?: () => void;
  scale?: [number, number]
} & ComponentProps
const Section = ({ id, className, children, ...props }: SectionProps) => (
  <section className={className} id={id}>
    <Parallax scale={[1.1, .9]}
      {...props}>
      <div>
        {children}
      </div>
    </Parallax>
  </section>
);

const BowTie = () => (
  <svg viewBox="0 0 256 256" width="40px" height="40px" style={{ marginTop: 20 }}>
    <path fill="#CAB57F" d="M128,123.59,16,71.74V184.26l112-51.85,112,51.85V71.74ZM24,171.74V84.26L118.48,128Zm208,0L137.52,128,232,84.26Z"/>
  </svg>
);

const Gift = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M22,12V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12A1,1 0 0,1 1,11V8A2,2 0 0,1 3,6H6.17C6.06,5.69 6,5.35 6,5A3,3 0 0,1 9,2C10,2 10.88,2.5 11.43,3.24V3.23L12,4L12.57,3.23V3.24C13.12,2.5 14,2 15,2A3,3 0 0,1 18,5C18,5.35 17.94,5.69 17.83,6H21A2,2 0 0,1 23,8V11A1,1 0 0,1 22,12M4,20H11V12H4V20M20,20V12H13V20H20M9,4A1,1 0 0,0 8,5A1,1 0 0,0 9,6A1,1 0 0,0 10,5A1,1 0 0,0 9,4M15,4A1,1 0 0,0 14,5A1,1 0 0,0 15,6A1,1 0 0,0 16,5A1,1 0 0,0 15,4M3,8V10H11V8H3M13,8V10H21V8H13Z" />
  </svg>
);

const Question = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
  </svg>
);

export const People = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
  </svg>
);

export const Person = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
  </svg>
);

export const Couple = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M16.5,12A2.5,2.5 0 0,0 19,9.5A2.5,2.5 0 0,0 16.5,7A2.5,2.5 0 0,0 14,9.5A2.5,2.5 0 0,0 16.5,12M9,11A3,3 0 0,0 12,8A3,3 0 0,0 9,5A3,3 0 0,0 6,8A3,3 0 0,0 9,11M16.5,14C14.67,14 11,14.92 11,16.75V19H22V16.75C22,14.92 18.33,14 16.5,14M9,13C6.67,13 2,14.17 2,16.5V19H9V16.75C9,15.9 9.33,14.41 11.37,13.28C10.5,13.1 9.66,13 9,13Z" />
  </svg>
);

const Envelope = () => (
  <svg style={{ width: '24px', height: '24px'}} viewBox="0 0 24 24">
    <path fill="currentColor" d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6M20 6L12 11L4 6H20M20 18H4V8L12 13L20 8V18Z" />
  </svg>
);

const IndexPage = ({ data }: any) => {
  const [introTimeout, setIntroTimeout] = useState<boolean>();
  const [hasScrolled, setHasScrolled] = useState<boolean>();
  const [bookNowLink, setBookNowLink] = useState<string|undefined>();
  const [password, setPassword] = useState<string|undefined>();
  useEffect(() => {
    setTimeout(() => {
      if (window.scrollY < 50) {
        setIntroTimeout(true);
      }
    }, 4000);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      history.replaceState('', 'The Cantú\'s',
        window.location.pathname + window.location.search);
    }
  }, []);



  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>The Cantú's</title>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://thecantus.com"
              }]
            }
         `}
        </script>
      </Helmet>
      <Section scale={[1, .6]} id='logo'>
        <Border className='logo center'>
          <Letters className='logo-letters' />
        </Border>
        <h1 className='date'>October 1st</h1>
      </Section>

      <Section onEnter={() => setHasScrolled(true)} id='intro'>
        <GatsbyImage image={getImage(data.stepsImage)!}
          alt=""
          style={{ maxWidth: '80vw' }}/>
        <h1>We're Getting Married!</h1>
        <h2 style={{ marginBottom: 12 }}>Celebrate with us</h2>
        <h2>Boston, MA &nbsp;|&nbsp; October 1st, 2022</h2>
      </Section>

      <hr/>

      <Section id='our-story'>
        <h1 className='uppercase'>Our Story</h1>
        <p>
          Our love story started out when we were high school seniors.
          Both of us needed a prom date and were connected by a mutual friend.
          We hit it off instantly. Within two weeks, we were dating.
          Neither of us had planned on meeting our life partner so soon and so we had
          to spend our time in college separately. During those five years of being
          apart, we had to adjust to different time zones and schedules.
          Despite our physical distance our hearts have always been together.
          We grew up together but more importantly, we grew as one.
        </p>
        <ImageGrid/>
      </Section>

      <hr/>

      <Section id='schedule'>
        <h1 className='uppercase'>Schedule</h1>
        <div className='location'>
          <h4>Cathedral of the Holy Cross</h4>
          <p>1400 Washington Street <br/> Boston, MA 02118</p>
          <div className='schedule-item'>
            <h3><span>Ceremony</span><span>2:00pm</span></h3>
          </div>
        </div>

        <div className='location'>
          <h4>Boston Park Plaza</h4>
          <p>50 Park Plaza<br/> Boston, MA 02116</p>
          <div className='schedule-item'>
            <h3><span>Cocktail Hour</span><span>6:30pm</span></h3>
          </div>
          <div className='schedule-item'>
            <h3><span>Reception</span><span>7:30pm</span></h3>

            <Link to='/faqs#what-should-i-wear' style={{ textDecoration: 'none' }}>
              <BowTie />
              <div className='schedule-item'>
                <h3 className='just-text' style={{ marginTop: 10 }}>BLACK TIE</h3>
              </div>
            </Link>

          </div>
        </div>

        <div className='location'>
          <h4>After Party</h4>
          <p>Boston Park Plaza</p>
          <div className='schedule-item'>
            <h3><span>The Square</span><span>12:00am</span></h3>
          </div>
        </div>
      </Section>

      <hr/>

      <Section id='schedule'>
        <h1 className='uppercase'>Accommodations</h1>
        <div className='location'>
          <h4>Boston Park Plaza</h4>
          <p>50 Park Plaza<br/> Boston, MA 02116</p>
          <div className='schedule-item'>
            <h3 className='just-text small'>reception held here</h3>
            <h3 className='just-text small'>book directly below for discounted rate</h3>
          </div>

          <button onClick={event => {
            addRipple(event.currentTarget, event);
            setBookNowLink('https://book.passkey.com/e/50293876');
          }}>
            BOOK NOW
          </button>
        </div>

        <div className='location'>
          <h4>The Whitney</h4>
          <p>170 Charles St<br/> Boston, MA 02114</p>
          <div className='schedule-item'>
            <h3 className='just-text small'>book directly below for discounted rate</h3>
          </div>

          <button onClick={event => {
            addRipple(event.currentTarget, event);
            setBookNowLink('https://reservations.travelclick.com/112936?groupID=3395443');
          }}>
            BOOK NOW
          </button>
        </div>

        <div className='location'>
          <h4>Moxy Hotel</h4>
          <p>240 Tremont Street<br/> Boston, MA 02116</p>
          <div className='schedule-item'>
            <h3 className='just-text small'>book directly below for discounted rate</h3>
          </div>

          <button onClick={event => {
            addRipple(event.currentTarget, event);
            setBookNowLink('https://www.marriott.com/event-reservations/reservation-link.mi?id=1659973966800&key=GRP&app=resvlink');
          }}>
            BOOK NOW
          </button>
        </div>
      </Section>

      <hr/>

      <Section id='more-info'>
        <h1 className='uppercase' style={{ marginBottom: 90 }}>More Info</h1>

        <Link to='/rsvp' className='internal-link'>
          <Envelope />
          <h2>RSVP</h2>
        </Link>

        <Link to='/registry' className='internal-link'>
          <Gift/>
          <h2>REGISTRY</h2>
        </Link>

        <Link to='/faqs' className='internal-link'>
          <Question />
          <h2>FAQs</h2>
        </Link>

        <Link to='/wedding-party' className='internal-link'>
          <People />
          <h2>WEDDING PARTY</h2>
        </Link>
      </Section>

      <hr/>

      <Section id='outro'>
        <GatsbyImage image={getImage(data.bppImage)!}
          style={{
            maxHeight: '50vh',
            width: '600px',
            height: '800px',
            maxWidth: '80vw',
          }}
          objectFit='contain'
          alt='' />
        <h1>We Can't Wait to See You!</h1>
      </Section>

      <MadeWithLove />
      <Modal show={!!bookNowLink} onClose={() => setBookNowLink(undefined)}>
        <h2 className='uppercase'>Enter Password</h2>
        <p>Password can be found in the back of the Save the Date</p>

        <form>
          <input type='password'
            placeholder='password'
            onChange={event => setPassword(event.currentTarget.value)}
            onClick={event => {
              addRipple(event.currentTarget, event);
            }}
          />
          <br/>
          <button
            disabled={password !== 'trisolinicantu'}
            type='submit'
            onClick={event => {
              addRipple(event.currentTarget, event);
              window.open(bookNowLink);
              setBookNowLink(undefined);
              event.preventDefault();
            }}>
            BOOK NOW
          </button>
        </form>

      </Modal>
      {introTimeout && <ScrollArrow show={!hasScrolled}/>}
    </main>
  );
};
export default IndexPage;
