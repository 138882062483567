import React from 'react';
import s from './scroll-arrow.module.scss';
import { c } from '../../utils';
import {ComponentProps} from '../../types';

export type ScrollArrowProps = {
  show?: boolean;
} & ComponentProps;
const ScrollArrow = ({ show, className, ...props }: ScrollArrowProps) => {
  return (
    <div className={c(s.container, show && s.show, className)}
         {...props}>
      <div className={s.chevron}/>
      <div className={s.chevron}/>
      <div className={s.chevron}/>
    </div>
  );
};

export default ScrollArrow;