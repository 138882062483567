import React from 'react';
import s from './image-grid.module.scss';
import {split} from '../../utils';
import {ComponentProps} from '../../types';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {Parallax} from 'react-scroll-parallax';
import Border from '../Border';

const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/our-story/"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        relativePath
      }
    }
  }`;
const ImageGrid = ({ className, ...props }: ComponentProps) => {
  const data = useStaticQuery(query);
  const chunkedImages = split(data.allFile.nodes, 4);
  return (
    <div className={s.imageGrid}>
      {
        chunkedImages.map((images, c: number) => (
          <div key={c}>
            {images?.map((img: IGatsbyImageData, r: number) => (
              <Parallax
                key={r}
                translateY={[-5 * (r + c + 1), 5 * (r + c + 1), 'easeInQuad']}>
                <GatsbyImage className={s.image}
                             key={r}
                             image={getImage(img)!}
                             alt='image' />
              </Parallax>
            ))}
          </div>
        ))
      }
    </div>
  );
};

export default ImageGrid;
